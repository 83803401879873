export default [
  {
    title: 'Inicio',
    route: 'inicio',
    icon: 'HomeIcon',
  },
  // {
  //   title: 'Grafana',
  //   icon: 'PieChartIcon',
  // },
  {
    header: 'Metas',
  },
  {
    title: 'Metas Azucar',
    route: 'metas-azucar',
    icon: 'ShoppingBagIcon',
    role: ['automatizacion', 'master'],
  },
  {
    title: 'Metas Molienda',
    route: 'metas-molienda',
    icon: 'BoxIcon',
    role: ['automatizacion', 'master'],
  },
  {
    header: 'Automatización',
  },
  {
    title: 'Alertas',
    role: ['automatizacion', 'master'],
    icon: 'AlertTriangleIcon',
    children: [
      {
        title: 'Historial',
        route: 'alertas-historial',
        icon: 'ActivityIcon',
      },
      {
        title: 'Lista',
        route: 'alertas',
        icon: 'ListIcon',
      },
      {
        title: 'Agregar',
        route: 'alertas-agregar',
        icon: 'PlusIcon',
      },
    ],
  },
  {
    title: 'Calibraciones',
    icon: 'SlidersIcon',
    role: ['automatizacion', 'master'],
    children: [
      {
        title: 'Control',
        route: 'calibraciones',
        icon: 'BarChartIcon',
      },
      {
        title: 'Equipos',
        route: 'calibraciones-equipos',
        icon: 'GridIcon',
      },
    ],
  },
  {
    title: 'Calibraciones Bio',
    icon: 'ActivityIcon',
    role: ['bioetanol', 'master'],
    children: [
      {
        title: 'Control',
        route: 'calibraciones-bio',
        icon: 'BarChartIcon',
      },
      {
        title: 'Equipos',
        route: 'calibraciones-bio-equipos',
        icon: 'GridIcon',
      },
    ],
  },
  {
    title: 'Detectores',
    route: 'detector-metales',
    icon: 'ClipboardIcon',
    role: ['automatizacion', 'master'],
  },
  {
    title: 'Elencos',
    icon: 'ServerIcon',
    role: ['automatizacion', 'master'],
    children: [
      {
        title: 'Armarios',
        route: 'elencos',
        icon: 'MapIcon',
      },
      {
        title: 'Buscador',
        route: 'buscador-elencos',
        icon: 'SearchIcon',
      },
      {
        title: 'Actualizar',
        route: 'equipos',
        icon: 'HardDriveIcon',
      },
    ],
  },
  {
    title: 'Bodegas',
    icon: 'PackageIcon',
    role: ['automatizacion', 'master', 'bodegas'],
    children: [
      {
        title: 'Herramientas',
        route: 'bodega-herramientas',
        icon: 'ToolIcon',
      },
      {
        title: 'Repuestos',
        route: 'bodega-repuestos',
        icon: 'ToolIcon',
      },
    ],
  },
  {
    title: 'Tareas',
    route: 'tareas',
    icon: 'TrelloIcon',
    role: ['automatizacion', 'master'],
  },
  {
    header: 'Muestreo',
  },
  {
    title: 'Laboratorios',
    icon: 'ThermometerIcon',
    role: ['laboratorio', 'master'],
    children: [
      {
        title: 'Muestras',
        route: 'laboratorio',
        icon: 'LayersIcon',
        role: ['laboratorio', 'master'],
      },
      {
        title: 'Registros',
        route: 'laboratorio-registros',
        icon: 'ClipboardIcon',
        role: ['laboratorio', 'master'],
      },
      {
        title: 'Opciones',
        route: 'laboratorio-configuracion',
        icon: 'SettingsIcon',
        role: ['lab_admin', 'master'],
      },
    ],
  },
  {
    header: 'Configuraciones',
  },
  {
    title: 'Configuración',
    route: 'configuraciones',
    icon: 'SettingsIcon',
    role: ['automatizacion', 'master'],
  },
  {
    title: 'Usuarios',
    route: 'usuarios',
    icon: 'UserIcon',
    role: ['automatizacion', 'master'],
  },
]
